import { type ChangeEvent, Fragment, useEffect, useState, useSyncExternalStore } from 'react'
import Head from 'next/head'
import { useRouter } from 'next/router'
import Script from 'next/script'
import { Button, InputField } from '@stone-payments/jade'
import { APP_ENV, APP_VERSION, DEPLOY_TYPE } from 'shared/envs'

import { isomorphicCookies } from '~/lib/helpers'

import { type SwhConfigStore, swhConfigStoreDefault, useSwhConfig } from './swh-config-context'

const microFrontEndLocalStorage = 'microFrontEndVersion'

type Fn<T> = (a: T) => unknown

const IS_PR_PREVIEW = DEPLOY_TYPE() === 'preview'

export class Store<T extends object> {
  private subscribers = new Set<Fn<T>>()

  constructor(private state: T) {}

  public get = () => {
    return this.state
  }

  public set = (callback: (t: T) => T) => {
    this.state = callback(this.state)
    this.subscribers.forEach(subscriber => subscriber(this.state))
  }

  public subscribe = (instance: Fn<T>) => {
    this.subscribers.add(instance)
    return () => this.subscribers.delete(instance)
  }
}

const broadcast = <T extends { payload: any; type: string }>(message: T, origin: string) =>
  window.postMessage(JSON.stringify(message), { targetOrigin: origin })

export const MICROFRONTEND_CDN =
  process.env.NODE_ENV === 'development'
    ? 'http://swh.local:9999'
    : APP_ENV() === 'prod'
    ? 'https://swh-cdn-prd.stone.com.br'
    : 'https://swh-cdn-stg.stone.com.br'

export const broadcastRerender = () => broadcast({ payload: {}, type: '@dashboard/rerender' }, window.location.origin)

declare global {
  interface Window {
    STONE_SWH: Store<SwhConfigStore>
  }
}

const isSsr = typeof window === 'undefined'

const store = new Store(swhConfigStoreDefault)

if (!isSsr) {
  window.STONE_SWH = store
}

export const useSwhGlobalStore = () => {
  const state = useSyncExternalStore(store.subscribe, store.get, store.get)
  return [state, store] as const
}

const VersionSelection = (props: { version: string | undefined; setVersion: (version: string) => void }) => {
  const router = useRouter()
  const [showVersionConfig, setShowVersionConfig] = useState(false)
  const [versionInput, setVersionInput] = useState<string | undefined>(props.version)
  const onChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    setVersionInput(e.target.value)
  }

  const onSubmit = () => {
    localStorage.setItem(microFrontEndLocalStorage, versionInput || '')
    router.replace({
      pathname: router.pathname,
      query: { ...router.query, __swh: versionInput }
    })
  }

  const onReset = () => {
    localStorage.removeItem(microFrontEndLocalStorage)
    const { __swh, ...newquery } = router.query
    console.info('Remove version from url:', __swh)
    router.replace({
      pathname: router.pathname,
      query: newquery
    })
  }

  if (APP_ENV() === 'prod') {
    return null
  }
  return (
    <div className="absolute left-1/2 center top-2 z-max bg-white border-r-8" style={{ zIndex: '1001' }}>
      {!showVersionConfig ? (
        <div>
          <Button variant="danger-ghost" onClick={() => setShowVersionConfig(!showVersionConfig)}>
            {' '}
            Version: {props.version ? props.version : 'latest'}
          </Button>
        </div>
      ) : (
        <div className="flex flex-col py-4 px-2 border-r-2 gap-jade-100">
          <span className="text-jade-150">Version: </span>
          <InputField
            placeholder="0.0.0"
            label=""
            className="text-jade"
            onChange={onChangeInput}
            value={versionInput}
          />
          <div className="flex justify-between">
            <Button variant="primary-ghost" onClick={onSubmit}>
              Apply
            </Button>
            <Button variant="primary-ghost" onClick={onReset}>
              Reset
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}

export const Microfrontend = ({ base }: { base: string }) => {
  const [, dispatch] = useSwhGlobalStore()
  const router = useRouter()
  const config = useSwhConfig()
  const [origin, setOrigin] = useState(isSsr ? '' : window.location.origin)
  const [basename] = useState(() => (IS_PR_PREVIEW ? `/${APP_VERSION()}/${base}` : `/${base}`))
  const [version, setVersion] = useState<string | undefined>(
    (router.query?.['__swh'] as string) || (localStorage.getItem(microFrontEndLocalStorage) as string)
  )

  const [localFile] = useState((): string => (version ? `/swh/${version}/entrypoint.js` : '/swh/root.js'))

  useEffect(() => {
    const pushState = window.history.pushState
    const rerender = async (callback: Function) => {
      setOrigin(window.location.origin)
      await callback()
      return void broadcastRerender()
    }

    history.pushState = (...args: any) => {
      const newUrl = args[2] || ''
      if (newUrl) {
        if (newUrl && IS_PR_PREVIEW) {
          const r = newUrl.replace(`/${APP_VERSION()}/`, '/')
          return void rerender(async () => router.push(r, undefined, { shallow: true }))
        }
        return void rerender(async () => router.push(newUrl, undefined, { shallow: true }))
      }
    }
    return () => void (history.pushState = pushState)
  }, [])

  useEffect(() => {
    const cookies = isomorphicCookies.getAll()
    if (cookies.token) {
      window.document.cookie = `token=${cookies.token};domain=${MICROFRONTEND_CDN}`
    }
  }, [router.pathname])

  useEffect(() => {
    if (!dispatch) return
    dispatch.set(prev => ({ ...prev, ...config }))
  }, [config.store?.document])

  useEffect(() => {
    if (process.env.NODE_ENV === 'development') return
    const queryStringValue = router.query['__swh']
    if (queryStringValue) {
      localStorage.setItem(microFrontEndLocalStorage, queryStringValue as string)
      return
    }
    const localStorageValue = localStorage.getItem(microFrontEndLocalStorage)
    if (!queryStringValue && localStorageValue) {
      router.replace(
        {
          pathname: router.pathname,
          query: { ...router.query, __swh: localStorageValue }
        },
        undefined,
        { shallow: true }
      )
    }
  }, [])

  useEffect(() => {
    setOrigin(window.location.origin)
    broadcastRerender()
  }, [router.asPath, router.pathname, router.query])

  if (config === swhConfigStoreDefault) {
    return null
  }

  return (
    <Fragment>
      <Head>
        <link rel="stylesheet" href={`${MICROFRONTEND_CDN}/swh/style.css`} />
      </Head>
      <VersionSelection version={version} setVersion={setVersion} />
      <div
        id="swh-container"
        className="w-full"
        data-origin={origin}
        data-basename={basename}
        data-href={router.asPath}
      />
      <Script type="module" src={`${MICROFRONTEND_CDN}${localFile}`} async crossOrigin="anonymous" />
    </Fragment>
  )
}
