import { useRouter } from 'next/router'
import { GroupHeader, Icon, IconShape, ListItem, Tag } from '@stone-payments/jade'
import jadeTheme from '@stone-payments/jade-design-tokens/cjs/stone'
import { analiticaInstance } from 'shared/analitica'

import { Box } from '~/domains/platform/design-system'
import { type Entity, type Person, type Subject } from '~/domains/platform/infra/deus-ex-machina/types'
import BlackBird, { type Routes } from '~/domains/platform/lib/blackbird'
import { For } from '~/domains/platform/lib/utilities-components'
import { entitiesListFactory } from '~/lib/helpers/auth'
import { isomorphicCookies, stringFormat } from '~/lib/helpers/utils'

import analitica from '../analitica'
export function sidebarSortSwitchAccountByPriority(arr: Person[]) {
  return arr.sort((a, b) => (a.documentType === 'CPF' ? -1 : 1) - (b.documentType === 'CPF' ? -1 : 1))
}

export function SidebarActionButtonSwitchAccount({
  entity,
  callback
}: {
  entity: Entity<'acquirer' | 'banking'>
  callback: () => void
}) {
  function handleClick() {
    analitica.events.profileMenu.click()
    callback()
  }

  return (
    <Box py={jadeTheme.global.space[50]}>
      <ListItem
        navigation
        content={
          <ListItem.Content
            description={stringFormat.document(entity.document)}
            label={entity.displayName}
            clampLine={['label', 'description']}
          />
        }
        onClick={handleClick}
      />
    </Box>
  )
}

export function SidebarSwitchAccount({
  entity,
  subject,
  callback
}: {
  entity: Entity<'acquirer' | 'banking'>
  subject: Subject<'stone_account_resources'>
  callback: () => void
}) {
  const { users, organizations } = subject

  const { asPath } = useRouter()

  const query = BlackBird.getQuery()
  const route = BlackBird.getPathname()

  const entities = entitiesListFactory({ subject, users, organizations })

  const handleGoToSwitchAccount = (item: Person) => {
    return function eventCallback() {
      BlackBird.replace({ pathname: route, query: { ...query } }, asPath as Routes, {
        shallow: true
      })

      isomorphicCookies.set('identityId', item.id)

      analiticaInstance.updateMetadata({ identity: item.id })

      callback()
    }
  }

  return (
    <>
      <Box py={jadeTheme.global.space[50]}>
        <ListItem
          content={<ListItem.Content label="Voltar" />}
          leading={<Icon size="medium" use="arrow-left" />}
          onClick={() => callback()}
          data-testid="icon-button-container"
        />
      </Box>
      <GroupHeader title="Selecione a conta" />
      <For
        of={sidebarSortSwitchAccountByPriority(entities)}
        render={(item, index) => (
          <ListItem
            key={index}
            data-testid={`entity-group:${item.id}`}
            leading={
              <IconShape
                size="small"
                use={stringFormat.documentType(item.document || '') === 'CPF' ? 'person' : 'shop'}
                variant="neutral"
              />
            }
            content={
              <ListItem.Content
                description={stringFormat.document(item.document)}
                label={item.displayName}
                clampLine={['label', 'description']}
              />
            }
            onClick={handleGoToSwitchAccount(item)}
            navigation={item.id === entity.id ? false : true}
            trailing={
              !item.roles.banking && !item.roles.acquirer ? (
                <Tag size="small" variant="neutral">
                  Pendente
                </Tag>
              ) : item.id === entity.id ? (
                <Tag size="small" variant="positive" solid>
                  Ativa
                </Tag>
              ) : null
            }
            variant={item.id === entity.id ? 'active' : 'neutral'}
          />
        )}
      />
    </>
  )
}
