export const CHALLENGE_BLOCKED_PTBR = '/pin-bloqueado'

export const STONE_ACCOUNT_ROUTES = [
  '/login',
  '/sair',
  //reset de senha
  '/resetar-senha/informe-seu-email',
  '/resetar-senha/confira-seu-email',
  '/aplicativos-conectados-a-conta',
  '/aplicativos-conectados',
  '/nao-consigo-entrar',
  '/seguranca',
  '/conta-em-analise',
  '/confirme-email',
  '/conta-bloqueada',
  '/fora-de-servico',
  '/bloqueado',
  CHALLENGE_BLOCKED_PTBR,
  '/troca-de-pin',
  '/informe-de-rendimentos',
  '/confira-o-app',
  '/autocred',
  '/conta-pj',
  '/seguranca-otp',
  // dados cadastrais
  '/dados-cadastrais',
  '/dados-cadastrais/meu-negocio/editar',
  '/dados-cadastrais/meus-negocios',
  '/dados-cadastrais/meu-negocio/editar/nome-fantasia',
  '/dados-cadastrais/meu-negocio/editar/confirmar',
  '/dados-cadastrais/meus-dados/editar',
  '/dados-cadastrais/meus-dados/editar/dados-pessoais',
  '/dados-cadastrais/meus-dados/editar/confirmar-nome',
  '/dados-cadastrais/meus-dados/editar/confirmar-dados-pessoais',
  //trocar de email
  '/dados-cadastrais/meus-dados/alterar-email',
  '/dados-cadastrais/meus-dados/alterar-email/analise',
  '/dados-cadastrais/meus-dados/alterar-email/verificacao-de-seguranca',
  '/dados-cadastrais/meus-dados/alterar-email/confirme-identidade-no-app',
  '/dados-cadastrais/meus-dados/editar/dados-pessoais/email-ja-utilizado',
  '/stone-account/registration-data/change-email/incomplete-registration',
  '/dados-cadastrais/meus-dados/editar/dados-pessoais/dados-em-analise',
  '/stone-account/registration-data/change-email/incomplete-registration',
  // multiusuários
  '/multiusuarios/equipe',
  // detalhamento de permissões concedidas e convites enviados
  '/multiusuarios/equipe/convite-enviado',
  '/multiusuarios/equipe/acessos-concedidos',
  '/multiusuarios/equipe/acessos-recebidos',
  '/selecionar-conta',
  '/multiusuarios/equipe/convite-recebido',
  // cadastro de multiusuários
  '/equipe/confirmar-dados',
  '/equipe/criar-senha',
  '/equipe/acessar-agora',
  '/equipe/convite-expirado',
  // quando criação de convite está desabilitda na web
  '/equipe/convide-pelo-app',
  // criação de convite
  '/multiusuarios/criar-convite/confirmar-dados',
  '/multiusuarios/criar-convite/dados-do-convidado',
  '/multiusuarios/criar-convite/senha',
  // alteração de perfil de acesso
  '/multiusuarios/alterar-perfil-de-acesso/selecionar-perfil',
  '/multiusuarios/alterar-perfil-de-acesso/detalhes-do-perfil',
  '/multiusuarios/alterar-perfil-de-acesso/resumo-da-alteracao',
  '/multiusuarios/alterar-perfil-de-acesso/inserir-senha',
  // solicitação de conta
  '/solicitar-conta/onboarding',
  '/solicitar-conta/escolha-o-tipo'
] as const
