export type Target =
  | 'Política de Privacidade'
  | 'Termos de Serviço'
  | 'Limites'
  | 'Reserva Stone'
  | 'Simulador'
  | 'Vendas'

interface EventScomnpose {
  label: string
  option?: string
  properties?: Record<string, string>
  type: 'CLICKED' | 'VIEWED' | 'CLOSE' | 'ERROR'
}

const EVENTS_TARGET: Record<Target, EventScomnpose> = {
  'Política de Privacidade': {
    label: 'home web - profile selected',
    properties: { option: 'política de privacidade' },
    type: 'CLICKED'
  },
  'Termos de Serviço': {
    label: 'home web - profile selected',
    properties: { option: 'termos de serviços' },
    type: 'CLICKED'
  },
  Limites: { label: 'home web - profile selected', properties: { option: 'limites' }, type: 'CLICKED' },
  'Reserva Stone': { label: 'home web - investment selected', type: 'CLICKED' },
  Simulador: { label: 'home web - lateral menu button chosen', type: 'CLICKED', option: 'simulador de vendas' },
  Vendas: { label: 'home web - sales selecte', type: 'CLICKED' }
}

export const eventHelper = new Proxy(EVENTS_TARGET, {
  get(target: typeof EVENTS_TARGET, key: keyof typeof EVENTS_TARGET) {
    return key in target ? target[key] : null
  }
})
