import snakecaseKeys from 'snakecase-keys'

import { loanHomeMock } from '~/domains/credit/loans/loan-home/components/loan-home.mock'
import { useCheckConfigurationsWithRevolvingUnderMaintenanceMock } from '~/domains/credit/ports/hooks/use-check-configurations.mock'
import { checkCreditAccessResponseMock } from '~/domains/credit/ports/services/check-credit-access.mock'
import { type ScenarioMock } from '~/scaffold/mocks/types/mock-flags-types'

export const loanHomeMocksWithRevolvingInMaintenance: ScenarioMock[] = [
  {
    scenario: 'Giro fácil em manutenção',
    mocks: [
      {
        method: 'GET',
        url: '**/experience-bff-service/api/web/v1/home**',
        status: 200,
        delay: 0,
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        body: JSON.stringify(snakecaseKeys({ ...loanHomeMock }))
      },
      {
        method: 'GET',
        url: '**/api/web/v1/configurations**',
        status: 200,
        delay: 100,
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        body: JSON.stringify(snakecaseKeys({ ...useCheckConfigurationsWithRevolvingUnderMaintenanceMock }))
      },
      {
        method: 'GET',
        url: '**/api/web/v1/home/check-credit-access**',
        status: 200,
        delay: 100,
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        body: JSON.stringify(snakecaseKeys({ ...checkCreditAccessResponseMock }))
      }
    ]
  }
]
