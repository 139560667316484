import { type PropsWithChildren, type ReactNode } from 'react'
import { type NavigationBarProps, NavigationBar } from '@stone-payments/jade'

// eslint-disable-next-line @typescript-eslint/ban-types
export const PageCard = (props: PropsWithChildren<{}>) => (
  <section className="border border-solid border-jade-border-low flex flex-col gap-jade-200 p-jade-200 rounded-jade-small !font-inter">
    {props.children}
  </section>
)

type ViewMode = 'fullscreen' | 'container' | 'centered'

type PageSwhContainerProps = NavigationBarProps & {
  description?: ReactNode
  viewMode?: ViewMode
}

const convertViewModeInClass = (viewMode: ViewMode) => {
  const defaultClass = 'grid mx-auto p-jade-250 gap-jade-250 '

  const viewModeClassMap: Record<ViewMode, string> = {
    centered: 'container max-w-3xl pt-jade-300',
    fullscreen: 'w-full',
    container: 'container'
  }

  return `${viewModeClassMap[viewMode] || viewModeClassMap.container} ${defaultClass}`
}

export const PageSwhContainer = ({ viewMode = 'container', ...props }: PropsWithChildren<PageSwhContainerProps>) => {
  return (
    <div className="relative !font-inter pt-jade-150" id="swh-page">
      <NavigationBar
        background="body"
        label={props.label}
        onNavigate={props.onNavigate || undefined}
        navigateType={props.navigateType}
        trailingAction={props.trailingAction}
        trailingItem={props.trailingItem}
      />
      <div className={convertViewModeInClass(viewMode)}>{props.children}</div>
    </div>
  )
}
