import { type QueryFunctionContext, useQuery } from '@tanstack/react-query'

import { Endpoints } from '../endpoints'
import { httpClient } from '../http-client'
import { handleErrorResponse } from '../utils'

const ITEMS_PER_PAGE = '50'

export namespace Metrics {
  export enum MetricsStatus {
    Created = 'created',
    PaidAndFinished = 'paidAndFinished'
  }
  export type Response = {
    items: Item[]
    nextPageCursor: string
  }

  export type Item = {
    id: string
    name: string
    document: string
    stateRegistration: string
    cnae: string
    salesTotalQuantity: number
    salesTotalQuantityWithItems: number
    salesTotalQuantityWithLooseValues: number
    salesTotalPrice: number
    salesTotalPayments: number
    looseValuesTotalPrice: number
    looseValuesTotalQuantity: number
    itemsTotalPrice: number
    itemsTotalQuantity: number
    averageTicketPrice: number
  }

  export type Filter = {
    initialDate: Date
    endDate: Date
    status: string
  }

  export type EditedFields = {
    salesWithItemsPending: number
    salesWithItemsFinished: number
    looseSalesPending: number
    looseSalesFinished: number
    totalSalesPending: number
    totalSalesFinished: number
  }

  const getMetrics = async (ctx: QueryFunctionContext): Promise<Response> => {
    const [, status] = ctx.queryKey

    const today = new Date()
    const oneYearAgo = new Date()
    oneYearAgo.setFullYear(today.getFullYear() - 1)

    const to = today.toISOString()
    const from = oneYearAgo.toISOString()

    try {
      const params = new URLSearchParams()
      params.append('from', from)
      params.append('to', to)

      if (status === MetricsStatus.PaidAndFinished) {
        params.append('status', 'paid')
        params.append('status', 'finished')
      } else {
        params.append('status', status as string)
      }

      params.append('pageSize', ITEMS_PER_PAGE)

      const response = await httpClient.get<Response>(Endpoints.metrics.getMetrics(), {
        params
      })
      return response.data
    } catch (error: unknown) {
      return handleErrorResponse(error)
    }
  }
  export function useFetchMetrics(status: string) {
    return useQuery<Response, unknown>({
      queryKey: ['metrics', status],
      queryFn: getMetrics,
      refetchOnWindowFocus: false,
      enabled: !!status
    })
  }
}
