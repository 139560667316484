import { type FlagProps } from '~/scaffold/mocks/types/mock-flags-types'

import { loanHomeMocksWithCreditAreaInMaintenance } from './loan-home-with-credit-area-in-maintenance'
import { loanHomeMocksWithLoansInMaintenance } from './loan-home-with-loans-in-maintenance'
import { loanHomeMocksWithRevolvingInMaintenance } from './loan-home-with-revolving-credit-in-maintenance'
import { loanRenegociationGraceCard } from './loan-renegociation-grace-card.mock'
import { loanRenegociationGraceDetails } from './loan-renegociation-grace-details.mock'

const creditHomeFlags: FlagProps[] = [
  {
    name: 'loan-renegociation-grace-card',
    groupName: 'Alavanca de Carência',
    scenarios: [
      'Renegociação ativa com carência',
      'Renegociação ativa sem carência',
      'Renegociação em processo com carência',
      'Renegociação em processo sem carência'
    ],
    initialScenario: 'Renegociação ativa com carência',
    handlers: loanRenegociationGraceCard
  },
  {
    name: 'loan-renegociation-grace-details',
    groupName: 'Alavanca de Carência',
    scenarios: ['Renegociação ativa com carência'],
    initialScenario: 'Renegociação ativa com carência',
    handlers: loanRenegociationGraceDetails
  },
  {
    name: 'loan-home-with-revolving-credit-in-maintenance',
    groupName: 'Telas de manutenção',
    scenarios: ['Giro fácil em manutenção'],
    initialScenario: 'Giro fácil em manutenção',
    handlers: loanHomeMocksWithRevolvingInMaintenance
  },
  {
    name: 'loan-home-with-credit-area-in-maintenance',
    groupName: 'Telas de manutenção',
    scenarios: ['Área de crédito emm manutenção'],
    initialScenario: 'Área de crédito em manutenção',
    handlers: loanHomeMocksWithCreditAreaInMaintenance
  },
  {
    name: 'loan-home-with-loans-in-maintenance',
    groupName: 'Telas de manutenção',
    scenarios: ['KGiro em manutenção'],
    initialScenario: 'KGiro em manutenção',
    handlers: loanHomeMocksWithLoansInMaintenance
  }
]

export { creditHomeFlags }
