const useCheckConfigurationsWithoutProductsUnderMaintenanceMock = {
  traceKey: '',
  isValid: true,
  content: {
    productsUnderMaintenance: [],
    allProductsIsUnderMaintenance: false
  }
}
const useCheckConfigurationsWithRevolvingUnderMaintenanceMock = {
  traceKey: '',
  isValid: true,
  content: {
    productsUnderMaintenance: ['revolving'],
    allProductsIsUnderMaintenance: false
  }
}
const useCheckConfigurationsWithCreditAreaUnderMaintenanceMock = {
  traceKey: '',
  isValid: true,
  content: {
    productsUnderMaintenance: [],
    allProductsIsUnderMaintenance: true
  }
}
const useCheckConfigurationsWithLoansUnderMaintenanceMock = {
  traceKey: '',
  isValid: true,
  content: {
    productsUnderMaintenance: ['loans'],
    allProductsIsUnderMaintenance: false
  }
}
export {
  useCheckConfigurationsWithCreditAreaUnderMaintenanceMock,
  useCheckConfigurationsWithLoansUnderMaintenanceMock,
  useCheckConfigurationsWithoutProductsUnderMaintenanceMock,
  useCheckConfigurationsWithRevolvingUnderMaintenanceMock
}
