import { analiticaInstance } from 'shared/analitica'

import { type Analytics } from '~/domains/home-v2/types'

export default analiticaInstance.eventFactory('Sidebar', {
  mainMenu: {
    /**
     * @analitica When the user click on lateral menu
     */
    click: (analytics: Analytics) => ({
      label: analytics?.eventName,
      metadata: analytics?.properties
    })
  },
  balanceCard: {
    /**
     * @analitica When the user click or view on balance card
     */
    hideBalance: (option: 'hide' | 'show') => ({
      label: 'home web - hide balance selected',
      metadata: {
        option
      }
    }),
    actionClick: ({ eventName, type, properties }: Analytics) => ({
      label: eventName,
      metadata: {
        type,
        properties
      }
    })
  },
  profileMenu: {
    /**
     * @analitica When the user click on go back button
     */
    back: () => ({
      label: 'home web - menu perfil chosen button',
      metadata: { 'menu-perfil': 'botao-voltar' }
    }),
    click: () => ({
      label: 'home web - change account selected'
    })
  }
})
