export const SWH_URL_CATALOG = '/produtos'
export const SWH_URL_CATALOG_CREATE = '/produtos/cadastro'
export const SWH_URL_CATALOG_EDIT = '/produtos/${id}'
export const SWH_URL_SALES = '/swh/vendas'
export const SWH_URL_INVOICES = '/notas-fiscais'
export const SWH_URL_INVOICE_SETTINGS = '/configuracoes/nota-fiscal'
export const SWH_URL_CLIENTS = '/clientes'

export const SWH_ROUTES_SALES: string[] = ['/swh/sales', SWH_URL_SALES]

export const SWH_ROUTES_CATALOG_SOURCE = [SWH_URL_CATALOG, SWH_URL_CATALOG_CREATE, SWH_URL_CATALOG_EDIT] as const

export const SWH_ROUTES_CATALOG = [
  '/swh/products',
  '/swh/products/new',
  '/swh/products/edit/[id]',
  ...SWH_ROUTES_CATALOG_SOURCE
] as const

export const SWH_ROUTES_INVOICE: string[] = ['/swh/invoices', SWH_URL_INVOICES]

export const SWH_ROUTES_INVOICE_SETTINGS: string[] = ['/swh/settings/invoice', SWH_URL_INVOICE_SETTINGS]

export const SWH_ROUTES_CLIENTS: string[] = ['/swh/clients', SWH_URL_CLIENTS]

export const SWH_ROUTES = [
  ...SWH_ROUTES_CATALOG,
  SWH_URL_INVOICE_SETTINGS,
  SWH_URL_INVOICES,
  SWH_URL_CATALOG_CREATE,
  SWH_URL_SALES,
  SWH_URL_CLIENTS,
  '/swh/vendas/${id}'
] as const
