import { Heimdall } from '~/domains/platform/core/heimdall'

import analitica from './analitica'

export const stoneAccountProfileMenu = () => {
  return [
    {
      order: 1,
      label: 'Dados cadastrais',
      icon: 'avatar-outline',
      show: true,
      onClick: () => {
        analitica.events.profileMenu.click('dados cadastrais')
      },
      path: '/dados-cadastrais',
      active: (route: string) => ['/stone-account/registration-data', '/dados-cadastrais'].includes(route)
    },
    {
      order: 4,
      label: 'Segurança',
      icon: 'lock-close-outline',
      show: true,
      onClick: () => {
        analitica.events.profileMenu.click('seguranca')
      },
      path: '/seguranca',
      active: (route: string) => ['/stone-account/auth/security', '/seguranca'].includes(route)
    },
    {
      order: 8,
      label: 'Aplicativos conectados',
      icon: 'menu-app-outline',
      show: Heimdall.pass(['wip_dispute']),
      onClick: () => {
        analitica.events.profileMenu.click('aplicativos conectados')
      },
      path: '/aplicativos-conectados',
      active: (route: string) => ['/stone-account/connected-apps', '/aplicativos-conectados'].includes(route)
    }
  ]
}
